<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement">
         <div class="container">
            <div class="row align-items-center">
               <div class="col-12 col-md-4 position-relative zeroFee">
                     <img src="/assets/images/zero-fee.webp" alt="Zero Fee" title="Zero Fee" class="img-fluid position-relative">
                  </div>
                  <div class="col-12 col-md-8">
                     <div class="text-center text-md-end">
                        <h1 class="mb-0">{{contentlist.pricing_content1}}</h1>
                        <p class="d-block mt-3 mt-lg-4 mb-0">{{contentlist.pricing_content2}}</p>
                        <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium mt-3 mt-lg-5">{{contentlist.get_started_content}}</a>
                     </div>
                  </div>
            </div>
         </div>
      </section>
      <section class="blockElement lightgreybg">
         <div class="container">
            <div class="tab d-flex justify-content-center hideMobile">
               <ul class="nav nav-pills">
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 1}]" @click.prevent="tab = 1">{{contentlist.pricing_content3}}</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 2}]" @click.prevent="tab = 2">{{contentlist.pricing_content4}}</a>
                  </li>
               </ul>
            </div>
            <div class="tabContent mt-4 mt-lg-5">
               <div class="showTabs-in-mob" :class="[{'active':tab == 1}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 1">{{contentlist.pricing_content3}}<vue-feather size="25px" type="chevron-down"></vue-feather></a>
               </div>
               <div v-if="tab == 1" class="pt-4 pt-lg-0">
                  <table class="table table-striped bg-white pricing-table borderedT">
                     <thead>
                              <tr>
                                 <th>{{contentlist.pricing_content5}}</th>
                                 <th>{{contentlist.pricing_content}} <a href="javascript:void(0);" class="tooltipBox"><img src="/assets/images/secondary-info.webp" alt="info" title="info" class="img-fluid" /><span class="tooltiptext"><div class="tooltiptextArrow">&nbsp;</div> {{contentlist.network_fees_apply_content}}</span></a></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>{{contentlist.pricing_content7}}</td>
                                 <td>{{contentlist.zero_content}}</td>
                              </tr>
                              <tr>
                                 <td>{{contentlist.pricing_content8}}</td>
                                 <td>{{contentlist.pricing_content9}}</td>
                              </tr>
                              <tr>
                                 <td>{{contentlist.pricing_content10}}</td>
                                 <td>{{contentlist.pricing_content9}}</td>
                              </tr>
                              <tr>
                                 <td>{{contentlist.pricing_content11}}</td>
                                 <td>{{contentlist.zero_content}}</td>
                              </tr>
                              
                           </tbody>
                           </table>
               </div>
               <div class="showTabs-in-mob pt-3 pb-lg-0" :class="[{'active':tab == 2}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 2">{{contentlist.pricing_content4}}<vue-feather size="25px" type="chevron-down"></vue-feather></a>
               </div>
               <div v-if="tab == 2" class="pt-4 pt-lg-0">
                  <table class="table table-striped bg-white pricing-table borderedT">
                     <thead>
                              <tr>
                                 <th>{{contentlist.pricing_content5}}</th>
                                 <th>{{contentlist.pricing_content}} <a href="javascript:void(0);" class="tooltipBox"><img src="/assets/images/secondary-info.webp" alt="info" title="info" class="img-fluid" /><span class="tooltiptext"><div class="tooltiptextArrow">&nbsp;</div> {{contentlist.network_fees_apply_content}}</span></a></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>{{contentlist.pricing_content12}}</td>
                                 <td>{{contentlist.pricing_content13}} <a href="javascript:void(0);" class="tooltipBox"><img src="/assets/images/primary-info.webp" alt="info" title="info" class="img-fluid" /><span class="tooltiptext"><div class="tooltiptextArrow">&nbsp;</div> {{contentlist.network_fees_apply_content}}</span></a></td>
                              </tr>
                              <tr v-if="entityName == 'apec'">
                                 <td>{{contentlist.pricing_content14}}</td>
                                 <td>{{contentlist.pricing_content15}} <a href="javascript:void(0);" class="tooltipBox"><img src="/assets/images/primary-info.webp" alt="info" title="info" class="img-fluid" /><span class="tooltiptext"><div class="tooltiptextArrow">&nbsp;</div> {{contentlist.pricing_content16}} <br /> {{contentlist.pricing_content17}}</span></a></td>
                              </tr>
                              
                              
                           </tbody>
                           </table>
                  
                  
               </div>
            </div>
            <p class="mt-4 text-center text-md-start">{{contentlist.pricing_content18}} 
            <br><br>
            {{contentlist.pricing_content19}}
         </p>
         </div>

        
      </section>

      <section class="blockElement" data-aos="fade-up" data-aos-duration="2000">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center mb-4">
                    <h2 class="borderBefore position-relative d-inline-flex">{{contentlist.questions_you_may_have_content}}</h2>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="accordion" id="accordionExample">
                        <!-- <div class="accordion-item"  v-for="value,key,index in faqslist.dataArr" :key="index+1" :val="value">
                            <h6 class="accordion-header medium" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Is there a cost associated with signing up?</button>
                            </h6>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body pt-0">
                                    <p class="mb-0">No! Registration is free. <br>The same applies for integration in case you sign up for a Merchant account.</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="accordion-item">
                            <h6 class="accordion-header medium" id="headingTwo">
                                <button class="accordion-button collapsed accordion-button-arrow" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What are the supported currencies for withdrawals?</button>
                            </h6>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body pt-0">
                                   <p class="mb-0">We support withdrawals in:</p>
                                    <ul>
                                       <li>
                                          Cryptos
                                       </li>
                                       <li>
                                          Cryptos
                                       </li>
                                       <li>
                                          Fiat currencies, however, fiat payouts are subject to approval by our compliance team upon verification of your account. 
                                       </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h6 class="accordion-header medium" id="headingThree">
                                <button class="accordion-button collapsed accordion-button-arrow" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Is there an additional cost for automatic payouts?</button>
                            </h6>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body pt-0">
                                    <p class="mb-0">No! Either manual or automated, the payouts in crypto currencies are free of charge. In case withdrawals in Fiat are enabled in your account, the transactions are being charged with 1%.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-5">
               <router-link :to="entityPath+'/help-centre'" class="themeBtn medium">{{contentlist.explore_more_content}}</router-link>
            </div>
        </div>
    </section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>

import { commonAllmixins } from '@/plugins/commonAll'
export default {
  mixins: [commonAllmixins], 
  data() {
    return {
      tab: 1,
      settings: {
         itemsToShow: 1,
         itemsToScroll:3,
         snapAlign: 'start',
      },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 2,
        itemsToScroll:2,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 4.5,
        itemsToScroll:4.5,
        snapAlign: 'start',
      },
    },
    };
  },
  created(){
        this.call_faqs()

    },
    methods:{
        call_faqs(){
            let sendData = {
                'globalParams':{},
                'localParams':{'parameter':this}
            }
            this._runGetFaqsList(sendData)
        },
        
    }
};
</script>